<template>
    <div class="flex justify-between">
        <StatusButton v-for="(count, status) in currentStatuses" :key="status" :active="selectedClientType === status" @click="statusClicked(status)">
            <template #label>{{ capitalizeFirstLetter(status) }}</template>
            <template #count>{{ count }}</template>
        </StatusButton>
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "../factoring.api.js";

export default {
    data() {
        return {
            currentStatuses: [],
            selectedClientType: "approvedCount",
            clientTypes: {
                all: ["Alla", "Beviljade", "Ohanterade", "Pågående", "Ej signerade", "Stoppade"],
            },
            invoiceTypes: {
                all: ["Alla", "Betalda", "Delbetalda", "Förfallna", "Överbetalda", "Obetalda"],
            },
        };
    },

    components: {
        StatusButton: () => import("@/pages/invoices/components/StatusButton.vue"),
    },

    created() {
        if (!this.isGlobalClientSelected) this.getAll();
    },

    methods: {
        async getAll() {
            const { search, clientId } = queryString.parse(window.location.search, { sort: false });
            this.currentStatuses = await Api.getCounts(search, clientId);
            const query = queryString.parse(window.location.search, { sort: false });
            this.selectedClientType = query.status ? query.status : "approvedCount";
        },

        statusClicked(status) {
            this.selectedClientType = status;
            const query = queryString.parse(window.location.search, { sort: false });
            console.log("🚀 ~ statusClicked ~ query:", query);
            query.pageNumber = 1;
            query.status = status;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("searchParametersChanged");
        },

        capitalizeFirstLetter(string) {
            const translation = {
                approvedCount: "Beviljade",
                notHandledCount: "Ohanterade",
                ongoingDraftCount: "Pågående",
                unsignedCount: "Ej signerade",
                stoppedCount: "Stoppade",
                totalClients: "Alla",
            };
            return translation[string];
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
